<template>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line
                hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="notifications" :search="search">
            <template v-slot:item="row">
                <tr>
                    <td>{{ row.item.bridgeName }}</td>
                    <td>{{ row.item.cota }}</td>
                    <td>{{ row.item.deviceId }}</td>
                    <td>{{ row.item.measurementTime }}</td>
                    <td>{{ row.item.pileId }}</td>
                    <td>{{ row.item.status_tag }}</td>
                    <td v-if="row.item.read_status"><v-btn elevation="2" color="success">Leído</v-btn></td>
                    <td v-else><v-btn elevation="2" color="error" @click="markNotificationAsReaded(row.item.deviceId, row.item.measurementTime)">Marcar como Leído</v-btn></td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'Alerts',
    data() {
        return {
            search: '',
            headers: [
                { text: 'Puente', align: 'start', value: 'puente' },
                { text: 'Cota', value: 'cota' },
                { text: 'Sensor', value: 'sensor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Pila', value: 'pila' },
                { text: 'Estado', value: 'estado' },
            ],
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications
        })
    },
    methods: {
        markNotificationAsReaded(deviceId, measurementTime) {
            var response = {}
            response.deviceId = deviceId;
            response.measurementTime = measurementTime;
            response.id_notif = deviceId+'/'+measurementTime;
            this.$store.commit('markNotification', response);
        }
    }
}
</script>